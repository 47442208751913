import React, { useCallback, useState } from "react";
import InputText from "../input-text";

const InputPassword = ({
    ...props
}) => {

    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = useCallback(() => {
        setShowPassword(!showPassword)
    }, [showPassword])

    return (
        <InputText iconSrc={`/images/icon/${showPassword ? "hide" : "show"}-eye.svg`} onIconClick={toggleShowPassword} type={showPassword ? "text" : "password"} {...props} />
    );
};

export default InputPassword;
