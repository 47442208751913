import React, { useCallback, useEffect, useRef, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { useRecoilValue } from "recoil";
import axios from "axios";
import { useAtom } from "jotai";
import Routes from "./Routes";
import { client } from "./common/apollo/ApolloClient";
import { getFirebaseInstance } from "./common/firebase";
import AuthContext from "./common/contexts/auth";
import TrackingContext from "./common/contexts/tracking";
import "./common/utils/facebook";
import {
  organizationState,
  authState,
  checkUserAuthState,
  hasCreatedNewUserAuthState,
} from "./atoms";
import {
  CHECK_USER,
  CREATE_USER,
} from "./graphqlQueries";
import {
  logout,
  onWindowBlurListener,
  visibilityChangeListner,
} from "./common/firebase/firebase";
import {
  deleteCookie,
  getCookie,
  setCookie,
} from "./utils";
import ScrollProvider from "./common/contexts/scroll";

import { useOrganization, useUserOrganizationPermissionData } from "./hooks";
import { accessTokenExpiryTime, commonAuthDeleteCookies, commonRemoveAuthEvents } from "./common/auth/utils";
import { refreshAutodeskAccessToken } from "./common/auth";
import ToastContextWithProvider, { useToaster } from "./common/contexts/toast";
import PageLoader from "./components/page-loader";

const App = () => {

  const { showToaster } = useToaster()
  const firebase = getFirebaseInstance();
  const logInMode = getCookie("__login_mode");

  // local states
  const [loading, setLoading] = useState({
    google: true,
    autodesk: true,
  });
  const [error, setError] = useState(defaultError);

  // global states
  // jotai
  const [auth, setAuth] = useAtom(authState);
  const [checkUserAuth, setCheckUserAuth] = useAtom(checkUserAuthState);
  const [hasCreatedNewUser, setHasCreatedNewUser] = useAtom(
    hasCreatedNewUserAuthState
  );

  //recoil
  const organization = useRecoilValue(organizationState);
  // const autodeskAuth = useRecoilValue(autodeskAuthState);
  const calledAutodeskLoginRef = useRef()

  // hooks
  const { fetchUserOrgPermission } = useUserOrganizationPermissionData();
  const { refreshOrganization } = useOrganization();

  const [tracking] = useState(
    (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "qa") &&
      process.env.REACT_APP_TRACKING_ID
      ? mixpanel
      : null
  );

  const commonRemoveSessionItems = useCallback(
    (logInMode) => {
      setAuth({
        user: null,
        isAuthenticated: false,
      });

      if (logInMode === "autodesk") {
        setLoading((oldState) => ({ ...oldState, [logInMode]: false }));
      }

      // if (logInMode === "google") {
      //   deleteCookie("__login_mode")
      // }

      setCheckUserAuth(null);
      commonAuthDeleteCookies()
      setHasCreatedNewUser(false);
    },
    [
      setAuth,
      setCheckUserAuth,
      setHasCreatedNewUser,
    ]
  );

  const afterSuccessLogIn = useCallback(
    async ({ token, userId, mode, authData, refreshToken = '' }) => {
      setCookie("__bauhub_token", token, accessTokenExpiryTime);
      setCookie("__login_mode", mode);

      if (refreshToken) {
        setCookie("__bauhub_refresh_token", refreshToken)
      }

      setAuth(authData);

      tracking && tracking.identify(userId);
      tracking && tracking.track("Login");

      document.addEventListener("visibilitychange", visibilityChangeListner);
      window.addEventListener("blur", onWindowBlurListener);

      fetchUserOrgPermission();
      setLoading((oldState) => ({ ...oldState, [mode]: false }));
      setError(defaultError)
    },
    [tracking, fetchUserOrgPermission, setAuth]
  );

  useEffect(() => {
    // removing auto logout event
    return () => {
      commonRemoveAuthEvents()
    };
  }, []);

  // checking user validatation for sign in and creating and signing in the user according to it
  // not used in callback because it was causing inside onAuthStateChanged
  const checkAndCreateUser = async ({ type, email, userData }) => {
    let checkUserData = checkUserAuth;
    // checking if user already exists in DB or not
    const checkUserResponse = await client.mutate({
      mutation: CHECK_USER,
      variables: {
        logInMode: type,
        email: email,
      },
    });
    checkUserData = checkUserResponse?.data?.checkUser;
    setCheckUserAuth(checkUserData);

    if (!checkUserData?.status) {
      throw new Error(checkUserData?.message || "Some error occured while sign in");
    }

    // to create user
    if (checkUserData?.isNewUser && !hasCreatedNewUser) {
      setHasCreatedNewUser(true);
      const newUserResponse = await client.mutate({
        mutation: CREATE_USER,
        variables: {
          input: {
            isNewUser: true,
            ...userData,
          },
        },
      });

      const newUserData = newUserResponse?.data?.createUser;

      if (!newUserData?.id) {
        setError(oldState => ({ ...oldState, [type]: checkUserData?.message || "Some error occured while saving user to bimmatch" }))
        throw new Error("Some error occured while saving user to bimmatch");
      }
    }

    return userData;
  };

  const onAuthStateChange = useCallback(
    async (user) => {
      if (auth.user) return auth.user;

      try {
        if (error.google) {
          setError((oldState) => ({ ...oldState, google: "" }));
        }
        const finalDisplayName =
          getCookie("__user_displayName") || user?.displayName || "";
        const userEmail = user.email.toLowerCase();
        const userData = {
          id: user?.uid,
          email: userEmail,
          displayName: finalDisplayName,
          logInMode: "google",
          photoUrl: user?.photoURL || "",
          emailVerified: user?.emailVerified,
        };

        await checkAndCreateUser({
          type: "google",
          email: userEmail,
          userData,
        });

        const idToken = await user.getIdToken(false);

        const authData = {
          user: {
            uid: user.uid,
            displayName: finalDisplayName,
            email: userEmail,
            photoURL: user.photoURL,
            idToken,
            emailVerified: user.emailVerified,
          },
          isAuthenticated: true,
          apis: firebase.auth,
        };

        afterSuccessLogIn({
          token: idToken,
          userId: user.uid,
          mode: "google",
          authData,
          refreshToken: user.refreshToken
        });
      } catch (error) {
        Sentry.captureException(error);
        deleteCookie("__user_displayName");
        window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
        logout(true);
        setError({ google: typeof error === "string" ? error : error.message })
      }
    },
    // have not included checkAndCreateUser has not been created using useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterSuccessLogIn, firebase.auth, auth.user]
  );

  // google sign in
  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User signed in
        await onAuthStateChange(user);
      } else if (logInMode === "google") {
        commonRemoveSessionItems("google");
      }
      setLoading((oldState) => ({ ...oldState, google: false }));
    });
    // have not included any dependecy to stop this hook from runnig more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logInMode]);

  const autodeskLogIn = useCallback((token) => {
    if (error.autodesk) {
      setError((oldState) => ({ ...oldState, autodesk: "" }));
    }
    calledAutodeskLoginRef.current = true;
    axios
      .get(`https://api.userprofile.autodesk.com/userinfo`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async ({ data }) => {
        try {
          const {
            name,
            sub: userId,
            given_name: firstName,
            family_name: lastName,
            email,
            thumbnails: profileImages,
          } = data;

          const emailIdLowerCase = email.toLowerCase();
          const fullName = name || firstName
          const photoUrl = (profileImages?.sizeX50 || profileImages?.sizeX40 || profileImages?.sizeX20 || "")

          const commonUserData = {
            displayName: fullName,
            email: emailIdLowerCase,
            emailVerified: true
          }

          const userData = {
            id: userId,
            isNewUser: true,
            logInMode: "autodesk",
            photoUrl,
            ...commonUserData
          };

          await checkAndCreateUser({
            type: "autodesk",
            email: emailIdLowerCase,
            userData,
          });

          const authData = {
            user: {
              uid: userId,
              firstName,
              lastName,
              photoURL: photoUrl,
              idToken: token,
              ...commonUserData
            },
            isAuthenticated: true,
          };

          afterSuccessLogIn({
            token: token,
            userId,
            mode: "autodesk",
            authData,
          });
        } catch (error) {
          console.log("Autodesk sign in error", error);
          setError({ autodesk: typeof error === "string" ? error : error.message })
        }
      })
      .catch((error) => {
        console.log("Autodesk error", error);
        setError({ autodesk: typeof error === "string" ? error : error.message })
        commonRemoveSessionItems("autodesk");
      });
  },
    // have not included checkAndCreateUser because it has not been created using useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [afterSuccessLogIn, commonRemoveSessionItems]
  );

  const connectToAccHandler = useCallback(
    async (status, message) => {
      if (status) {
        await refreshOrganization();
      }
      showToaster(`connect-to-acc-toast`, message, status)
    },
    [refreshOrganization, showToaster]
  );


  // autodesk sign in
  useEffect(() => {
    const refreshToken = getCookie("__bauhub_refresh_token")
    const accessToken = getCookie("__bauhub_token")
    if (logInMode !== "google") {
      setLoading((oldState) => ({ ...oldState, google: false }));
    }

    if (logInMode === "autodesk" && accessToken && !calledAutodeskLoginRef?.current) {
      autodeskLogIn(accessToken);
    } else if (logInMode && logInMode === "autodesk" && !accessToken && refreshToken) {
      // to load refresh token when rereshed directly/when directly hit url
      // only call headers when refresh token is there but no access token
      refreshAutodeskAccessToken().then((token) => {
        calledAutodeskLoginRef.current = true
        autodeskLogIn(token);
      })
    } else {
      setLoading((oldState) => ({ ...oldState, autodesk: false }));
    }
    // have not included checkAndCreateUser & commonRemoveSessionItems because it has not been created using useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logInMode]);

  //reading event after success of autodesk login from popup window
  useEffect(() => {
    const messageRes = (message) => {
      const data = message.data;
      if (!data.service) {
        return;
      }

      if (data.service === "autodesk") {
        autodeskLogIn(data.access_token);
      }

      if (data.service === "connect-to-acc") {
        connectToAccHandler(data.status, data.message);
      }
    };
    window.addEventListener("message", messageRes);

    return () => {
      window.removeEventListener("message", messageRes);
    };
  }, [autodeskLogIn, connectToAccHandler]);

  if (
    loading.google ||
    loading.autodesk ||
    (auth.isAuthenticated && !organization?.id)
  ) {
    return (
      <PageLoader />
    );
  }

  return (
    <>
      <TrackingContext.Provider value={tracking}>
        <AuthContext.Provider value={auth}>
          <ApolloProvider client={client}>
            <ScrollProvider>
              <Routes authError={error} />
            </ScrollProvider>
          </ApolloProvider>
        </AuthContext.Provider>
      </TrackingContext.Provider>
    </>
  );
};

const defaultError = {
  google: "",
  autodesk: ""
}

export default () => (
  <ToastContextWithProvider>
    <App />
  </ToastContextWithProvider>
);
