import React, { useContext, useEffect, useMemo } from 'react'
import AuthContext from '../contexts/auth';
import { useHistory } from 'react-router-dom';

const AuthLayout = ({ children, withHeader, title }) => {
    const history = useHistory()
    const { user, isAuthenticated } = useContext(AuthContext);
    const isAuthorizedUser = useMemo(() => isAuthenticated && user?.emailVerified, [user, isAuthenticated])

    useEffect(() => {
        if (isAuthenticated) {
            history.push(isAuthorizedUser ? "/products" : "/verification")
        }
    }, [history, isAuthenticated, isAuthorizedUser])

    return (
        <div className='auth-layout-wrapper'>
            <div className='branding'>
                <div className='branding-wrapper'>
                    <img src='/images/logo-with-shadow.svg' alt='logo' className='bm-logo-with-shadow' />
                    <div className='bm-slogan'>
                        <h1 className='slogan-content'>Information that’s ready in the context of what you need to do</h1>
                    </div>
                </div>
            </div>
            <div className='content-wrapper'>
                {
                    withHeader && (
                        <div className='login-header'>
                            <img src='/images/logo.svg' alt='logo' className='bm-logo' />
                            <h1 className='login-title'>{title}</h1>
                        </div>
                    )
                }
                {children}
            </div>
        </div>
    )
}

export default AuthLayout