import React, { cloneElement, useContext, useMemo } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "./common/contexts/auth";
import BaseLayout from "./common/layout/BaseLayout";
import { ConditionalWrapper } from "./components";
import PageLoader from "./components/page-loader";

const PrivateRoute = ({
  children,
  authAccess = true,
  withoutLayout = false,
  ...props
}) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const isAuthorizedUser = useMemo(() => isAuthenticated && user?.emailVerified, [user, isAuthenticated])

  return (
    <Route
      {...props}
      render={(props) => (isAuthorizedUser && authAccess) ? (
        <React.Suspense fallback={<PageLoader />}>
          <ConditionalWrapper condition={!withoutLayout} Wrapper={<BaseLayout />}>
            {cloneElement(children, props)}
          </ConditionalWrapper>
        </React.Suspense>
      ) : (
        <Redirect
          to={{
            pathname: isAuthenticated ? "/verification" : "/signin",
          }}
        />
      )}
    />
  );
};

export default PrivateRoute;