import React, { useCallback, useEffect, useRef, useState } from "react";
import { fieldValidation } from "../../utils";
import ErrorText from "../error-text";
import LabelBox from "../label-box";
import ConditionalWrapper from "../conditional-wrapper"

const InputText = ({
  id,
  required = false,
  placeholder = "Type",
  label,
  isError = false,
  errorMessage,
  hideLabel = false,
  onChange,
  fieldName,
  validations,
  showValidationMessage = false,
  onValidationChange,
  infoPlacement,
  info,
  applyTrim = false,
  applyLowerCase = false,
  disabled = false,
  showSettings,
  onChangeSettings,
  defaultSettings,
  defaultValue,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  type,
  iconSrc,
  onIconClick,
  ...props
}) => {

  const [hasError, setHasError] = useState(isError)
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage)
  const settings = useRef(null)

  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  //updating state of on change of error & message
  useEffect(() => {
    setHasError(isError)
  }, [isError])

  useEffect(() => {
    setHasErrorMessage(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (defaultValue && !fieldValue.current) {
      const defaultFieldValue = {
        [fieldName]: defaultValue,
      }

      if (defaultSettings) defaultFieldValue.settings = defaultSettings
      fieldValue.current = defaultFieldValue
    }
  }, [defaultSettings, defaultValue, fieldName])


  const onChangeInput = useCallback((event) => {
    let value = event.target.value;
    if (applyTrim) {
      value = value.trim();
    }
    if (applyLowerCase) {
      value = value.toLowerCase();
    }
    const { isValid, message } = fieldValidation(validations ? { ...validations, type: 'string' } : { type: 'string' }, value, label)
    if (!isValid) {
      if (!hasError) setHasError(true)
      if (message) setHasErrorMessage(message)

    } else {
      if (hasError) setHasError(false)
      if (hasErrorMessage) setHasErrorMessage('')
    }
    onValidationChange && onValidationChange(isValid, message, value)

    if (fieldName) {
      const argData = { [fieldName]: value }
      if (settings?.current) argData.settings = settings?.current
      fieldValue.current = argData
      onChange && onChange(argData, event);

    } else {
      onChange && onChange(value, event);
    }
  },
    [fieldName, onChange, validations, hasError, hasErrorMessage, onValidationChange, label, applyTrim, applyLowerCase, settings]
  );

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onChange && onChange(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onChange, fieldName])

  return (
    <div
      className={`input-text ${(!hasError && !hasErrorMessage) ? "input-text-normal" : "input-text-error"
        } ${disabled ? "input-text-disabled unselectable" : ''}`}
    >
      {!hideLabel && <LabelBox required={required} label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete} />}
      <ConditionalWrapper condition={iconSrc} Wrapper={<InputWithIconWrapper iconSrc={iconSrc} onIconClick={onIconClick} />}>
        <input
          {...props}
          defaultValue={defaultValue}
          id={id}
          placeholder={placeholder}
          type={type || 'text'}
          onChange={onChangeInput}
          dir='auto'
        />
      </ConditionalWrapper>
      {showValidationMessage && <ErrorText message={hasErrorMessage} />}
    </div>
  );
};

const InputWithIconWrapper = ({ iconSrc, children, onIconClick }) => {

  const handleIconClick = useCallback(() => {
    if (onIconClick) {
      onIconClick()
    }
  }, [onIconClick])

  return (
    <div className="input-icon-wrapper">
      {children}
      <img src={iconSrc} alt="input-icon" className="input-icon" onClick={handleIconClick} />
    </div>
  )
}

export default InputText;
